import {useFocusEffect} from '@react-navigation/native';
import React from 'react';

interface Props {
  containerId: string;
}

const HollywoodBetsBanner = ({containerId}: Props) => {
  console.log('HollywoodBetsBanner', containerId);

  useFocusEffect(
    React.useCallback(() => {
      // const createScript = document => {
      const div = document.createElement('div');
      div.className = 'clever-core-ads';
      div.id = 'clever-core-ads';

      const myScript = document.createElement('script');
      myScript.setAttribute('data-cfasync', 'false');
      myScript.type = 'text/javascript';
      myScript.id = 'clever-core';
      myScript.innerHTML = `
      /* <![CDATA[ */
      (function (document, window) {
        var a,
          c = document.createElement('script'),
          f = window.frameElement;

        c.id = 'CleverCoreLoader82009';
        c.src =
          'https://scripts.cleverwebserver.com/fa3a6526569956b7e39960592bf0e30d.js';

        c.async = !0;
        c.type = 'text/javascript';
        c.setAttribute('data-target', window.name || (f && f.getAttribute('id')));
        c.setAttribute('data-callback', 'put-your-callback-function-here');
        c.setAttribute('data-callback-url-click', 'put-your-click-macro-here');
        c.setAttribute('data-callback-url-view', 'put-your-view-macro-here');

        try {
          a =
            parent.document.getElementsByTagName('script')[0] ||
            document.getElementsByTagName('script')[0];
        } catch (e) {
          a = !1;
        }

        a ||
          (a =
            document.getElementsByTagName('head')[0] ||
            document.getElementsByTagName('body')[0]);
        a.parentNode.insertBefore(c, a);

      })(document, window);
      /* ]]> */
      `;
      const container = document.getElementById(containerId);
      container?.appendChild(myScript);
      container?.appendChild(div);

      return () => {
        console.log('HollywoodBetsBanner cleanup');
        document.getElementById('clever-core')?.remove();
        document.getElementById('CleverCoreLoader82009')?.remove();
        document.getElementById('clever-core-ads')?.remove();
      };
    }, [containerId]),
  );

  // const d = React.createElement('div', {
  //   className: 'clever-core-ads',
  //   style: {
  //     height: '250px',
  //     width: '300px',
  //   },
  // });

  return <></>;
};

export default HollywoodBetsBanner;
